//
//
// utilities.scss
//
//
.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

@include media-breakpoint-up(lg) {
  .o-lg-visible {
    overflow: visible;
  }
}

// Opacity
//
// Control an element's opacity using the class .opacity-# element with value of 0 - 100
.opacity-0 {
  opacity: 0;
}

@for $i from 1 through 9 {
  $opacity: ($i / 10);
  .opacity-#{($i*10)} {
    opacity: $opacity;
  }
}

// Spacing
//
// Control an element's vertical (North South) padding with these utilities

section {
  padding: $default-section-padding 0;
}

// Height
//
// Control an elements min-height in vh units using the class .height-# with a value of 0 - 90

.h-0 {
  height: 0;
}

@for $i from 1 through 10 {
  $height: ($i * 10);
  .min-vh-#{$height} {
    min-height: #{$height}vh;
  }
}

@include media-breakpoint-up(md) {
  @for $i from 1 through 10 {
    $height: ($i * 10);
    .min-vh-md-#{$height} {
      min-height: #{$height}vh;
    }
  }
}

@include media-breakpoint-up(lg) {
  @for $i from 1 through 10 {
    $height: ($i * 10);
    .min-vh-lg-#{$height} {
      min-height: #{$height}vh;
    }
  }
}

// Width
//
// Control an elements min-width in vw units using the class .min-width-#vw with a value of 10 - 90

@for $i from 1 through 10 {
  $width: ($i * 10);
  .min-vw-#{$width} {
    min-width: #{$width}vw;
  }
}

// Text
//

.text-white {
  p {
    a {
      color: $white;
    }
  }
}

.text-strikethrough {
  text-decoration: line-through;
}

// Overlays
//

.overlay {
  .container {
    z-index: 1;
    position: relative;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  }

  .divider {
    z-index: 1;
  }

  &.card {
    &::after {
      border-radius: 0 0 $border-radius * 2 $border-radius * 2;
    }
  }

  > .position-absolute {
    z-index: 2;
  }
}

// Shadows
//

.shadow-3d {
  box-shadow: $shadow-3d;
}

// Alternative background colors
//

@each $name, $color in $theme-color-scheme {
  .bg-#{$name}-alt {
    // background: mix($color,$white,6%);
    background: $primary-6;
  }
}

// Borders

img.rounded,
div.rounded {
  border-radius: $border-radius * 2 !important;
}

.rounded-sm,
img.rounded-sm {
  border-radius: $border-radius !important;
}

.bg-primary-3 {
  &.border-bottom {
    border-color: rgba($white, 0.08) !important;
  }
}

// Position

.position-relative {
  z-index: 1;
}

.position-absolute {
  &[style] {
    z-index: 2;
  }
}

.sticky-top {
  top: $spacer;
}

// Z-index Layers

@for $i from 1 through 4 {
  .layer-#{$i} {
    z-index: $i;
  }
}

[class*="layer-"] {
  position: relative;
}

// Blend Modes

.blend-mode-multiply {
  mix-blend-mode: multiply;
}

// Transforms

.flip-x {
  transform: scaleX(-1);
}

.flip-y {
  transform: scaleY(-1);
}

.rotate-left {
  transform: rotateZ(-1deg);

  &.mobile-screenshot {
    transform: rotateZ(-5deg);
  }
}

.rotate-right {
  transform: rotateZ(1deg);
}
