.edukamu-cookie-preferences {
  border-radius: 0.2rem 0.2rem 0 0;
  border: 1px solid darken(#e9e9e9, 2%);
  position: fixed;
  bottom: 0;
  left: 50%;
  min-height: 100px;
  min-width: 600px;
  background-color: #e9e9e9;
  transform: translateX(-50%);
  transition: transform 400ms;
  padding: 2.5rem;
  z-index: 10000;

  &__title {
    margin-bottom: 0.8rem;
    font-size: 1.4em !important;
  }

  &__body {
    margin-bottom: 0.5rem;
    font-size: 1em !important;
  }

  [class*="edukamu-checkbox"] {
    margin-right: 0.5rem;
  }

  .edukamu-checkbox--disabled {
    margin-right: 0.5rem;
  }

  .edukamu-checkbox__input {
    background-color: #fff;

    &--disabled {
      border-color: #727272;

      &::before {
        color: #727272;
      }
    }
  }

  &__checkboxes {
    display: flex;
    margin: 0.8rem 0 0.5rem 0;
  }

  &--hide {
    @extend .edukamu-cookie-preferences;

    transform: translate(-50%, 100%);
  }

  @media (max-width: 600px) {
    min-width: 0;
    width: 100%;
    padding: 1.3rem 0.8rem 0.8rem 0.8rem;
  }
}
