/**
 * user.scss
 *
 * General styles used on all sites.
 * These will override bootstrap and other theme styles.
 */

// TODO:
// This file still has a bunch of site/domain specific code,
// move to their own files.

@import "user-components/loader-custom.scss";
@import "user-components/signin.scss";
@import "user-fonts.scss";

html {
  overflow-x: hidden !important;
}

body {
  /*
        Breaks sticky property
        https://uxdesign.cc/position-stuck-96c9f55d9526

        overflow-x: hidden !important;
    */
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.container-lg {
  padding-left: 15px;
  padding-right: 15px;
}

.container-xl {
  padding-left: 15px;
  padding-right: 15px;
}

.container-60 {
  max-width: 60%;
}

.container-80 {
  max-width: 80%;
}

@media only screen and (max-width: 768px) {
  .container-60,
  .container-80 {
    max-width: 100%;
  }
}

/* form field is required */
.form-label.required::after {
  content: "*";
  color: red;
}

/* form field is invalid */
.c-is-invalid {
  border: 1px solid red;
}

.img-with-margin {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

@media (max-width: 769.98px) {
  .navbar_between {
    display: none !important;
  }

  div.navbar_below {
    display: unset !important;
  }
}

@media (min-width: 770px) {
  .navbar-container {
    .navbar.navbar-expand-lg {
      div.navbar_below {
        display: none !important;
      }
    }
  }
}

// Backgrounds
.bg-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.bg-primary-2 {
  background-color: $primary-2 !important;
  border-color: $primary-2 !important;
}

.bg-primary-3 {
  background-color: $primary-3 !important;
  border-color: $primary-3 !important;
}

.bg-primary-4 {
  background-color: $primary-4 !important;
  border-color: $primary-4 !important;
}

.bg-primary-5 {
  background-color: $primary-5 !important;
  border-color: $primary-5 !important;
}

.bg-primary-6 {
  background-color: $primary-6 !important;
  border-color: $primary-6 !important;
}

.bg-edukamu {
  background-color: $edukamu !important;
  border-color: $edukamu !important;
}

.bg-edukamu-2 {
  background-color: $edukamu-2 !important;
  border-color: $edukamu-2 !important;
}

.bg-edukamu-3 {
  background-color: $edukamu-3 !important;
  border-color: $edukamu-3 !important;
}

.bg-edukamu-4 {
  background-color: $edukamu-4 !important;
  border-color: $edukamu-4 !important;
}

.bg-edukamu-5 {
  background-color: $edukamu-5 !important;
  border-color: $edukamu-5 !important;
}

.bg-lightgrey {
  background-color: $lightgrey !important;
  border-color: $lightgrey !important;
}

// Texts
.text-primary {
  color: $primary !important;
}

.text-primary-2 {
  color: $primary-2 !important;
}

.text-primary-3 {
  color: $primary-3 !important;
}

.text-primary-4 {
  color: $primary-4 !important;
}

.text-primary-5 {
  color: $primary-5 !important;
}

.text-primary-6 {
  color: $primary-6 !important;
}

.text-edukamu {
  color: $edukamu !important;
}

.text-edukamu-1 {
  color: $edukamu-1 !important;
}

.text-edukamu-2 {
  color: $edukamu-2 !important;
}

.text-edukamu-3 {
  color: $edukamu-3 !important;
}

.text-edukamu-4 {
  color: $edukamu-4 !important;
}

.text-edukamu-5 {
  color: $edukamu-5 !important;
}

// SVG
.svg-primary * {
  fill: $primary !important;
}

.svg-primary-2 * {
  fill: $primary-2 !important;
}

.svg-primary-3 * {
  fill: $primary-3 !important;
}

.svg-primary-4 * {
  fill: $primary-4 !important;
}

.svg-primary-5 * {
  fill: $primary-5 !important;
}

.svg-primary-6 * {
  fill: $primary-6 !important;
  transition: all $transition-time-slow ease !important;
}

.svg-edukamu * {
  fill: $edukamu !important;
}

.scrolled {
  .svg-primary-6 * {
    fill: white !important;
    transition: all $transition-time-slow ease !important;
  }

  .svg-kauppakamari-6 * {
    fill: white !important;
    transition: all $transition-time-slow ease !important;
  }
}

@media only screen and (max-height: 520px) {
  .navbar {
    background: #009aa6;
  }
}

// buttons
.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.btn-outline-primary {
  border-color: $primary !important;
  color: $primary !important;
  
  &:hover {
    background-color: $primary !important;
    color: white !important;
  }
}

.btn-edukamu-3 {
  background-color: $edukamu-3 !important;
  border-color: $edukamu-3 !important;

  /* stylelint-disable-next-line */
  * {
    fill: white !important;
  }
}

.custom_Article {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $primary-6;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
  }

  h4 {
    color: $primary-6;
    margin-bottom: 1rem;
  }

  h5 {
    color: $primary-6;
  }

  p + {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 2rem;
    }
  }

  p:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  hr {
    margin-top: 0.5rem;
    border-top-color: rgba($primary-4, 0.8);
  }

  a {
    font-weight: 400;
    color: $primary;
  }

  li {
    color: $primary-5;

    span {
      color: #212529;
    }

    ul {
      li:first-child {
        margin-top: 0.5rem;
      }
    }
  }

  svg {
    g {
      path {
        fill: $primary-6 !important;
      }
    }
  }
}

#chartjs-tooltip {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  transition: all 0.1s ease;
  pointer-events: none;
  transform: translate(-50%, 0);
  z-index: 1000;

  th {
    color: white;
    font-weight: bold;
  }

  .chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
}

.firebaseOmatTiedot {
  .tallennaButton {
    width: 10rem;
    margin: auto;
    padding: 0.4rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

// dropdown valikon näkyvyys muiden elementtien päällä
.multi-select {
  z-index: 10;
}
