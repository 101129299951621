@import "../../scss/user-variables.scss";

.browser-warning-panel {
  background-color: $primary-4;
  position: fixed;
  z-index: 10000;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 80px;

  .browser-warning-text {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }
}
