//
//
// ie-fixes.scss
//
// Fixes reported bugs associated with Flexbox for IE11 -- Exlucde this file if IE11 support is not required

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .card {
    .card-img-overlay {
      top: 0;
    }

    > a {
      flex-shrink: 0;
    }

    &.justify-content-end {
      .card-img-overlay {
        top: auto;
        bottom: 0;
      }
    }

    &:not([class*="col-"]) {
      width: 100%;
    }

    > div {
      max-width: 100%;
    }

    &.card-article-wide {
      .card-body {
        width: 100%;
      }
    }
  }

  div[class*="col"][class*="flex"] {
    &:not(.flex-column) {
      //display: block !important;
    }
  }

  .flex-column {
    > img.flex-grow-1 {
      flex-grow: 0 !important;
      flex-shrink: 0 !important;
    }
  }

  .icon {
    flex-shrink: 0 !important;
  }

  .icon-logo {
    width: auto !important;
    max-width: 6rem !important;
  }

  svg.icon {
    width: $spacer;

    &.icon-md {
      width: $spacer * 1.5;
    }

    &.icon-lg {
      width: $spacer * 2;
    }

    &.icon-sm {
      width: $spacer/2;
    }
  }

  img {
    flex-shrink: 0;

    &.flex-grow-1 {
      flex-grow: 0 !important;
    }
  }

  .list-group {
    width: 100%;
  }

  .row {
    width: 100%;
  }

  .text-image-2 {
    display: block;

    .bg-white {
      bottom: 0;
      right: 0;
    }
  }

  .video-cover {
    .video-play-icon {
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .video-play-icon {
    &::after {
      content: "Play";
      bottom: 51px;
      right: 5px;
      font-size: 0.5rem;
    }
  }

  .progress-bar {
    display: block;
    height: 6px;
  }

  .video-poster {
    .btn-round {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .process-vertical li:not(:last-child)::after {
    display: none;
  }

  .flickity-button-icon {
    display: block;
  }

  .min-vh-100 {
    height: 100vh;
  }
}
