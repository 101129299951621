//
//
// tables.scss
//
//

th {
  font-weight: $font-weight-bold;
  color: $headings-color;
}

.table-dark {
  th {
    color: $white;
  }
}

.table {
  thead {
    th {
      border-bottom-width: $border-width;
    }
  }
}

article {
  table {
    margin-bottom: $spacer;
    border-top: $border-width solid $border-color;

    td {
      vertical-align: top;
      padding-bottom: $spacer/2;
      padding-top: $spacer/2;

      &:not(:last-child) {
        white-space: nowrap;
        padding-right: $spacer;
      }
    }

    thead {
      th {
        padding-top: $spacer/2;
        padding-bottom: $spacer/2;
        padding-right: $spacer;
      }
    }

    tr {
      border-bottom: $border-width solid $border-color;
    }
  }
}
