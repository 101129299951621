/* Common Loader CSS */

@keyframes hideLoader {
  0% {
    width: 100%;
    height: 100%;
  }

  100% {
    width: 0;
    height: 0;
  }
}

body > div.loader {
  position: fixed;
  background: white;
  width: 100%;
  height: 100%;
  z-index: 10000;
  opacity: 0;
  transition: opacity 0.5s ease;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

body:not(.loaded) > div.loader {
  opacity: 1;
}

body:not(.loaded) {
  overflow: hidden;
}

body.loaded > div.loader {
  animation: hideLoader 0.5s linear 0.5s forwards;
}

/* Typing Animation */
.loading-animation {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  animation: typing 1s linear infinite alternate;
  position: relative;
  left: -12px;
}

@keyframes typing {
  0% {
    background-color: rgba(100, 100, 100, 1);
    box-shadow: 12px 0 0 0 rgba(100, 100, 100, 0.2), 24px 0 0 0 rgba(100, 100, 100, 0.2);
  }

  25% {
    background-color: rgba(100, 100, 100, 0.4);
    box-shadow: 12px 0 0 0 rgba(100, 100, 100, 2), 24px 0 0 0 rgba(100, 100, 100, 0.2);
  }

  75% {
    background-color: rgba(100, 100, 100, 0.4);
    box-shadow: 12px 0 0 0 rgba(100, 100, 100, 0.2), 24px 0 0 0 rgba(100, 100, 100, 1);
  }
}
