//
//
// svg.scss
//
//

svg {
  &.icon {
    * {
      fill: theme-color("primary");
    }

    &[class*="bg-"] {
      background: none !important;
    }
  }

  &.bg-white {
    * {
      fill: $white;
    }
  }

  @each $name, $color in $theme-colors {
    &.bg-#{$name} {
      * {
        fill: $color;
      }
    }
    &.bg-#{$name}-alt {
      * {
        fill: mix($color, $white, 6%);
      }
    }
  }
}

.text-light {
  svg.icon {
    &:not([class*="bg-"]) {
      * {
        fill: $white;
      }
    }
  }
}

.text-primary {
  svg.icon {
    &:not([class*="bg-"]) {
      * {
        fill: theme-color("primary");
      }
    }
  }
}
