/**
 * user-variables.scss
 *
 * SCSS variables used on all sites.
 * These will override any Bootstrap and theme variables.
 */

// KAMK
$primary: rgb(0, 178, 227); // KAMK sininen
$primary-2: rgb(214, 0, 109); // KAMK Pinkki
$primary-3: rgb(97, 33, 102); // KAMK violetti
$primary-4: rgb(238, 118, 35); // KAMK oranssi
$primary-5: rgb(128, 188, 0); // KAMK vihreä
$primary-6: rgb(0, 154, 166); // KAMK Petrooli

// Kauppakamari
$kauppakamari: #2196f3; // Kauppakamari Blue: Button color
$kauppakamari-2: #ff97ca; // Kauppakamari Pink: Text highlight
$kauppakamari-3: rgb(97, 33, 102); // KAMK violetti
$kauppakamari-4: #ffc62f; // Kauppakamari Light yellow: Graphics highlight
$kauppakamari-5: #fff4fa; // Kauppakamari Light Pink: Background color
$kauppakamari-6: #002663; // Kauppakamari Dark Blue: Kauppakamari logo blue and text headers

// Edukamu
$edukamu: #000;
$edukamu-1: #fff;
$edukamu-2: #e4008a;
$edukamu-3: #3688fd;
$edukamu-4: #e8ebf3;
$edukamu-5: #6c4bd5;
$edukamu-6: #f3f3f3;

// Digitaidot
$digitaidot: #3320a3;

// Jumppakamu
$jumppakamu: #3e3f41;
$jumppakamu-1: $edukamu-3;
$jumppakamu-2: $edukamu-2;

// Päihteet
$paihteet: #f5e3d4;
$paihteet-2: #c6bab9;
$paihteet-3: #2b2b2b;
$paihteet-4: #808080;
$paihteet-5: #f9f1ed;

// Visitkamk
$visitkamk: #014c4a;
$visitkamk-2: #f251b4;
$visitkamk-3: #066;
$visitkamk-4: #0d9b8d;
$visitkamk-5: #b88a65;
$visitkamk-6: #3e3f41;
$visitkamk-7: #e00592;

// Kajaaniperehdytys
$kajaaniperehdytys: #2929cc;
$kajaaniperehdytys-2: #2b2b2b;
$kajaaniperehdytys-3: #f9f1ed;
$kajaaniperehdytys-4: #f1f1f1;
$kajaaniperehdytys-5: #e3e3e3;
$kajaaniperehdytys-6: #d5d5d5;
$kajaaniperehdytys-7: #c7c7c7;
$kajaaniperehdytys-8: #b9b9b9;
$kajaaniperehdytys-9: #949494;
$kajaaniperehdytys-10: #6f6f6f;
$kajaaniperehdytys-11: #4a4a4a;
$kajaaniperehdytys-12: #2b2b2b;
$kajaaniperehdytys-13: #0d754b;
$kajaaniperehdytys-14: #ff5671;
$kajaaniperehdytys-15: #35aa75;
$kajaaniperehdytys-16: #fdfbfa;

// Progress
$progress-notStarted: #e9ecef;
$progress-studying: #ffc62f;
$progress-pending: #612166;
$progress-completed: #35aa75;
$progress-tryAgain: #e4008a;

// Navbar
$navbar-brand-padding-y: 0;

// Fonts
$font-family-sans-serif: "Dosis";
$font-family-monospace: "Raleway";
$font-family-base: $font-family-sans-serif;

// Bootstrap
$lightgrey: rgb(240, 240, 240);

// Do not change or remove the $theme-color-scheme or $theme-colors maps below
$theme-color-scheme: (
  "primary": $primary,
  "primary-2": $primary-2,
  "primary-3": $primary-3,
  "primary-4": $primary-4,
  "primary-5": $primary-5,
  "primary-6": $primary-6,
);

$theme-colors: (
  "primary": $primary,
  "primary-2": $primary-2,
  "primary-3": $primary-3,
  "primary-4": $primary-4,
  "primary-5": $primary-5,
  "primary-6": $primary-6,
);
