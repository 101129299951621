//
//
// breadcrumb.scss
//
//

.breadcrumb {
  background-color: transparent;
}

.breadcrumb-item {
  height: $spacer;
  display: flex;
  align-items: center;
  font-size: $font-size-sm;

  a {
    transition: opacity $transition-time-fast linear;

    &:not(:hover) {
      opacity: 0.75;
    }
  }

  + .breadcrumb-item {
    &::before {
      content: "";
      background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z' fill='#{$body-color-escaped}'/></svg>");
      width: $spacer;
      height: $spacer;
      padding: 0;
      margin-right: $breadcrumb-item-padding;
      transform: rotateZ(180deg);
      opacity: 0.2;
    }
  }

  .icon {
    transform: rotateZ(180deg);
    height: 1rem;
    margin-left: 0.25rem;
    opacity: 0.2;
  }
}

.text-light {
  .breadcrumb-item {
    &.active {
      color: rgba($white, 0.5);
    }

    + .breadcrumb-item {
      &::before {
        background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z' fill='%23FFFFFF'/></svg>");
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .breadcrumb-item {
    + .breadcrumb-item {
      &::before {
        position: relative;
        top: 3px;
        margin-left: 0.5rem;
      }
    }
  }
}
