/* Common Loading Animation Code */

@keyframes hideLoader {
  0% {
    width: 100%;
    height: 100%;
  }

  100% {
    width: 0;
    height: 0;
  }
}

body > div.loader {
  position: fixed;
  background: white;
  width: 100%;
  height: 100%;
  z-index: 1071;
  opacity: 0;
  transition: opacity 0.5s ease;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

body:not(.loaded) > div.loader {
  opacity: 1;
}

body:not(.loaded) {
  overflow: hidden;
}

body.loaded > div.loader {
  animation: hideLoader 0.5s linear 0.5s forwards;
}

/* Clock Animation */
.loading-animation-clock {
  width: 24px;
  height: 24px;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #222;
  border-radius: 50%;
  position: relative;
  margin: 38px auto; /* Not necessary- its only for layouting */
}

.loading-animation-clock::after,
.loading-animation-clock::before {
  position: absolute;
  content: "";
  background-color: #222;
}

.loading-animation-clock::after {
  width: 10px;
  height: 2px;
  top: 11px;
  left: 11px;
  -webkit-transform-origin: 1px 1px;
  -moz-transform-origin: 1px 1px;
  transform-origin: 1px 1px;
  -webkit-animation: minhand 2s linear infinite;
  -moz-animation: minhand 2s linear infinite;
  animation: minhand 2s linear infinite;
}

.loading-animation-clock::before {
  width: 8px;
  height: 2px;
  top: 11px;
  left: 11px;
  -webkit-transform-origin: 1px 1px;
  -moz-transform-origin: 1px 1px;
  transform-origin: 1px 1px;
  -webkit-animation: hrhand 8s linear infinite;
  -moz-animation: hrhand 8s linear infinite;
  animation: hrhand 8s linear infinite;
}

@-webkit-keyframes minhand {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes minhand {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes minhand {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes hrhand {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes hrhand {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes hrhand {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
